import { css } from "@emotion/css";
import { Check } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import React from "react";

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const textClass2 = css`
  color: white;
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const advantagesClass = css`
  color: white;
  font-size: 1.8em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  color: white;
  font-size: 2.5em !important;
  text-align: center;
  font-weight: bold !important;
`;
const bigTitleClass = css`
  color: white;
  font-size: 3.5em !important;
  @media (max-width: 420px) {
    font-size: 3em !important;
  }
  text-align: center;
  font-weight: bold !important;
`;
const moduleTitle = css`
  font-size: 1.7em !important;
  text-align: center;
  font-weight: bold !important;
`;
const cardClass = css`
  max-width: 500px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
`;

export const FormationSection: React.FC = () => {
  return (
    <section
      className={css`
        background-color: rgba(59, 89, 152, 1);
        padding-bottom: 50px;
        ul {
          padding: 0;
          list-style: none;
          li {
            display: flex;
            svg {
              margin-top: 5px;
              margin-right: 10px;
            }
          }
        }
      `}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          className={css`
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: red;
          `}
        >
          <Typography className={titleClass} id="section-4-0">
            Sommaire du programme
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box
            className={css`
              ${cardClass};
              background-color: rgb(221, 255, 191);
            `}
          >
            <Typography className={moduleTitle} id="section-4-1">
              Module 1 - Les bases du code <br />
              (théorie et pratique)
            </Typography>
            <ul>
              <Typography className={textClass}>
                <li>
                  <Check /> Les Variables : comment sont stockées les données
                </li>
                <li>
                  <Check /> Les Types : string, number, boolean, Array, Object,
                  présentation et explications
                </li>
                <li>
                  <Check /> Les Conditions : la base d'un code complexe
                </li>
                <li>
                  <Check /> Les Boucles : le parcours de la donnée
                </li>
                <li>
                  <Check /> Les Fonctions : l'art de réutiliser le code
                </li>
                <br />À la fin du Module 1, vous aurez appris les composants
                essentiels d'un programme. Vous serez prêt à commencer à créer
                votre premier programme.
              </Typography>
            </ul>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box
            className={css`
              ${cardClass};
              background-color: rgb(207, 204, 255);
            `}
          >
            <Typography className={moduleTitle} id="section-4-2">
              Module 2 - Les raisons pour lesquelles vous pouvez échouer et
              abandonner
            </Typography>
            <ul>
              <Typography className={textClass}>
                <li>
                  <Check /> L'Anglais
                </li>
                <li>
                  <Check /> Un blocage sur l'algorithmie
                </li>
                <li>
                  <Check /> L'évolution constante des technologies
                </li>
                <li>
                  <Check /> La compréhension des erreurs de code
                </li>
                <li>
                  <Check /> La complexité du travail
                </li>
                <li>
                  <Check /> Bonus : Les avantages du métier
                </li>
                <br />À la fin du Module 2, vous saurez quels sont les
                principaux obstacles auquels vous ferez faces dans votre
                apprentissage mais également comment les surmonter.
              </Typography>
            </ul>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box
            className={css`
              ${cardClass};
              background-color: rgb(255, 224, 224);
            `}
          >
            <Typography className={moduleTitle} id="section-4-3">
              Module 3 - Votre premier programme
            </Typography>
            <ul>
              <Typography className={textClass}>
                <li>
                  <Check /> Présentation du sujet et accompagnement à sa
                  réalisation
                </li>
                <li>
                  <Check /> Correction et explications
                </li>
                <br />À la fin du Module 4, vous aurez acquis votre première
                expérience dans la programmation et aurez une idée plus claire
                de la construction d'un programme.
              </Typography>
            </ul>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box
            className={css`
              ${cardClass};
              background-color: rgb(221, 255, 191);
            `}
          >
            <Typography className={moduleTitle} id="section-4-4">
              Module 4 - Exemple du développeur web
            </Typography>
            <ul>
              <Typography className={textClass}>
                <li>
                  <Check /> Architecture d'une application Web
                </li>
                <li>
                  <Check /> Le Client : L'interface utilisateur
                </li>
                <li>
                  <Check /> Le Serveur : La gestion des données
                </li>
                <li>
                  <Check /> Démonstration de la mise en place d'une application
                  web (client et serveur)
                </li>
                <br />À la fin du Module 5, vous aurez une idée globale du
                travail d'un développeur web.
              </Typography>
            </ul>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box
            className={css`
              ${cardClass};
              background-color: rgb(207, 204, 255);
            `}
          >
            <Typography className={moduleTitle} id="section-4-5">
              Module 5 - Comment devenir développeur
            </Typography>
            <ul>
              <Typography className={textClass}>
                <li>
                  <Check /> École ou formation en ligne
                </li>
                <li>
                  <Check /> Comment obtenir son premier travail en entreprise
                </li>
                <li>
                  <Check /> CDI ou Freelance
                </li>
                <br />À la fin du Module 6, vous connaîtrez les choix qui vous
                sont possibles pour vous former et intégrer une entreprise, ou
                choisir de travailler à votre compte comme Freelance.
              </Typography>
            </ul>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="md">
        <br />
        <br />
        <Typography className={bigTitleClass} id="section-4-6">
          Les plus de la formation :
        </Typography>
        <br />
        <Typography className={advantagesClass} id="section-4-7">
          - Une formation pas à pas
        </Typography>
        <br />
        <Typography className={textClass2}>
          Laissez-vous guider dans :
        </Typography>
        <ul
          className={css`
            list-style: initial !important;
            li {
              display: list-item !important;
            }
            padding-left: 15px !important;
          `}
        >
          <Typography className={textClass2}>
            <li>l'apprentissage des bases du code</li>
            <li>la découverte du métier de développeur</li>
            <li>la création de votre premier programme</li>
          </Typography>
        </ul>
        <br />
        <Typography className={advantagesClass} id="section-4-8">
          - Des checklists complètes à chaque étape
        </Typography>
        <br />
        <Typography className={textClass2}>
          Pour toujours savoir quoi faire et dans quel ordre.
        </Typography>
        <br />
        <Typography className={advantagesClass} id="section-4-9">
          - Une communauté d'entraide
        </Typography>
        <br />
        <Typography className={textClass2}>
          Posez vos questions dans l'espace communauté de la formation.
          <br />
          En cas de besoin je vous réponds personnellement.
        </Typography>
        <br />
      </Container>
    </section>
  );
};
