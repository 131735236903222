import React from "react";
import { css } from "@emotion/css";
import { Container, Typography } from "@mui/material";

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  font-size: 1.8em !important;
  text-align: center;
`;

export const StorySection: React.FC = () => {
  return (
    <section
      className={css`
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container maxWidth="md" id="section-1-0">
        <Typography className={textClass}>
          Vous avez probablement entendu parler de la programmation informatique
          mais vous n'avez jamais essayé et donc vous ne savez pas très bien en
          quoi cela consiste. Et à cause de cela vous risquez soit de passer à
          côté d'un métier qui aurait pu changer votre vie soit de foncer tête
          baissée et de vous rendre compte que ce n'est absolument pas pour
          vous.
        </Typography>
        <br />
        <Typography className={titleClass} id="section-1-2">
          Bon et du coup c'est quoi ?
        </Typography>
        <br />
        <Typography className={textClass}>
          La programmation informatique c'est créer des programmes sous diverses
          formes. Un site web comme celui que vous utilisez maintenant, une
          application mobile, un jeu vidéo ou voir même le système de pilotage
          d'un A380.
          <br />
          <br />
          Et pour faire tout cela vous allez écrire du code. Beaucoup de code.
        </Typography>
        <br />
        <Typography className={titleClass} id="section-1-1">
          Est-ce pour tout le monde ?
        </Typography>
        <br />
        <Typography className={textClass}>
          Pour faire simple... non.
          <br />
          <br />
          Je ne vais pas vous mentir et vous dire qu'avec des efforts tout le
          monde peut y arriver. Je pense que tout le monde peut apprendre, mais
          cependant sans un minimum de passion ce ne sera pas pour vous. Devenir
          développeur est difficile et si cela ne vous plaît pas vous aurez
          toutes les peines du monde à progresser.
        </Typography>
        <br />
        <Typography className={titleClass} id="section-1-2">
          Et comment savoir si ça va vous plaire ?
        </Typography>
        <br />
        <Typography className={textClass}>
          Pour cela pas de mystères vous allez devoir mettre les mains dans le
          code et essayer.
          <br />
          <br />
          Deux options s'offrent à vous :
          <br />
          <br />
          - Vous pouvez apprendre par vous-même. Vous avez beaucoup de contenu
          gratuit disponible sur internet. Une petite recherche "Apprendre le
          code" et vous voilà avec une tonne de vidéos ou de blogs à votre
          disposition pour vous lancer.
          <br />
          <br />- Vous pouvez vous laissez guider dans ma formation. Je vous
          apprends les bases du code et vous montre le métier de développeur gratuitement.
        </Typography>
        <br />
      </Container>
    </section>
  );
};
