const {
  REACT_APP_DEV_SERVER_URL,
  REACT_APP_SERVER_URL,
  REACT_APP_FACEBOOK_ADS,
  REACT_APP_READING_STATE,
  NODE_ENV,
} = process.env;

export const IS_DEVELOPMENT = NODE_ENV === "development";
export const SERVER_URL = (
  IS_DEVELOPMENT ? REACT_APP_DEV_SERVER_URL : REACT_APP_SERVER_URL
)!;
export const FACEBOOK_ADS_ROUTE = REACT_APP_FACEBOOK_ADS;
export const READING_STATE_ROUTE = REACT_APP_READING_STATE;

export enum EventName {
  PageView = "PageView",
  Lead = "Lead",
  Purchase = "Purchase",
}
