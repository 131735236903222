import { css } from "@emotion/css";
import { Box, Container, Typography } from "@mui/material";
import React from "react";

const listClass = css`
  padding-left: 15px;
  li {
    margin-bottom: 10px;
  }
`;

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  font-size: 2em !important;
  font-weight: bold !important;
  padding-top: 50px;
`;

const pageTitleCardClass = css`
  background-color: #1a237e;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const pageTitleClass = css`
  text-align: center;
  color: white;
  font-size: 3em !important;
  font-weight: bold !important;
`;

const pageSubTitleClass = css`
  text-align: center;
  color: white;
  font-size: 2em !important;
  font-weight: bold !important;
`;

export const PrivacyPolicyView: React.FC = () => {
  return (
    <Box>
      <Box className={pageTitleCardClass}>
        <Container>
          <Typography className={pageTitleClass}>
            POLITIQUE DE CONFIDENTIALITÉ
            <br />
            DU SITE :
          </Typography>
          <Typography className={pageSubTitleClass}>
            www.formation.paul-wery.fr
          </Typography>
        </Container>
      </Box>
      <Container
        maxWidth="sm"
        className={css`
          border-bottom: 1px solid black;
          padding-bottom: 100px;
          margin-bottom: 200px;
        `}
      >
        <Typography className={titleClass}>ARTICLE 1 : PRÉAMBULE</Typography>
        <br />

        <Typography className={textClass}>
          Cette politique de confidentialité s'applique au site :
          www.formation.paul-wery.fr.
        </Typography>
        <br />
        <Typography className={textClass}>
          La présente politique de confidentialité a pour but d'exposer aux
          utilisateurs du site :
        </Typography>
        <br />
        <ul className={listClass}>
          <Typography className={textClass}>
            <li>
              La manière dont sont collectées et traitées leurs données à
              caractère personnel. Doivent être considérées comme données
              personnelles toutes les données étant susceptibles d'identifier un
              utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de
              l'adresse postale, l'adresse mail, la localisation de
              l'utilisateur ou encore son adresse IP ;
            </li>
            <li>
              Quels sont les droits des utilisateurs concernant ces données ;
            </li>
            <li>
              Qui est responsable du traitement des données à caractère
              personnel collectées et traitées ;
            </li>
            <li>A qui ces données sont transmises ;</li>
            <li>
              Eventuellement, la politique du site en matière de fichiers
              "cookies".
            </li>
          </Typography>
        </ul>
        <br />
        <Typography className={textClass}>
          Cette politique de confidentialité complète les mentions légales et
          les Conditions Générales d'Utilisation que les utilisateurs peuvent
          consulter à l'adresse ci-après :
        </Typography>
        <br />
        <Typography className={textClass}>
          www.formation.paul-wery.fr
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT
          DE DONNÉES
        </Typography>
        <br />

        <Typography className={textClass}>
          Conformément aux dispositions de l'article 5 du Règlement européen
          2016/679, la collecte et le traitement des données des utilisateurs du
          site respectent les principes suivants :
        </Typography>
        <br />
        <ul className={listClass}>
          <Typography className={textClass}>
            <li>
              Licéité, loyauté et transparence : les données ne peuvent être
              collectées et traitées qu'avec le consentement de l'utilisateur
              propriétaire des données. A chaque fois que des données à
              caractère personnel seront collectées, il sera indiqué à
              l'utilisateur que ses données sont collectées, et pour quelles
              raisons ses données sont collectées ;
            </li>
            <li>
              Finalités limitées : la collecte et le traitement des données sont
              exécutés pour répondre à un ou plusieurs objectifs déterminés dans
              les présentes conditions générales d'utilisation ;
            </li>
            <li>
              Minimisation de la collecte et du traitement des données : seules
              les données nécessaires à la bonne exécution des objectifs
              poursuivis par le site sont collectées ;
            </li>
            <li>
              Conservation des données réduites dans le temps : les données sont
              conservées pour une durée limitée, dont l'utilisateur est informé.
              Lorsque cette information ne peut pas être communiquée,
              l'utilisateur est informé des critères utilisés pour déterminer la
              durée de conservation ;
            </li>
            <li>
              Intégrité et confidentialité des données collectées et traitées :
              le responsable du traitement des données s'engage à garantir
              l'intégrité et la confidentialité des données collectées.
            </li>
          </Typography>
        </ul>
        <br />
        <Typography className={textClass}>
          Afin d'être licites, et ce conformément aux exigences de l'article 6
          du règlement européen 2016/679, la collecte et le traitement des
          données à caractère personnel ne pourront intervenir que s'ils
          respectent au moins l'une des conditions ci-après énumérées :
        </Typography>
        <br />
        <ul className={listClass}>
          <Typography className={textClass}>
            <li>L'utilisateur a expressément consenti au traitement ;</li>
            <li>
              Le traitement est nécessaire à la bonne exécution d'un contrat ;
            </li>
            <li>Le traitement répond à une obligation légale ;</li>
            <li>
              Le traitement s'explique par une nécessité liée à la sauvegarde
              des intérêts vitaux de la personne concernée ou d'une autre
              personne physique ;
            </li>
            <li>
              Le traitement peut s'expliquer par une nécessité liée à
              l'exécution d'une mission d'intérêt public ou qui relève de
              l'exercice de l'autorité publique ;
            </li>
            <li>
              Le traitement et la collecte des données à caractère personnel
              sont nécessaires aux fins des intérêts légitimes et privés
              poursuivis par le responsable du traitement ou par un tiers.
            </li>
          </Typography>
        </ul>
        <br />

        <Typography className={titleClass}>
          ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS
          LE CADRE DE LA NAVIGATION SUR LE SITE
        </Typography>
        <br />

        <Typography className={textClass}>
          <strong>A.</strong> DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE
        </Typography>
        <br />
        <Typography className={textClass}>
          Les données à caractère personnel collectées sur le site
          www.formation.paul-wery.fr sont les suivantes :
        </Typography>
        <br />
        <Typography className={textClass}>- Adresse email </Typography>
        <br />
        <Typography className={textClass}>- (Possible) Prénom </Typography>
        <br />
        <Typography className={textClass}>
          - (En cas de paiement) Adresse postale
        </Typography>
        <br />
        <Typography className={textClass}>
          Ces données sont collectées lorsque l'utilisateur effectue l'une des
          opérations suivantes sur le site :
        </Typography>
        <br />
        <Typography className={textClass}>
          - Lorsque l'Utilisateur renseigne ces informations pour recevoir du
          contenu exclusif{" "}
        </Typography>
        <br />
        <Typography className={textClass}>
          - Lorsque l'Utilisateur effectue une commande sur le site
        </Typography>
        <br />
        <Typography className={textClass}>
          Par ailleurs, lors d'un paiement sur le site, il sera conservé dans
          les systèmes informatiques de l'éditeur du site une preuve de la
          transaction comprenant le bon de commande et la facture.
        </Typography>
        <br />
        <Typography className={textClass}>
          Le responsable du traitement conservera dans ses systèmes
          informatiques du site et dans des conditions raisonnables de sécurité
          l'ensemble des données collectées pour une durée de : Toutes données
          sont conservées pour une durée de 12 mois.
        </Typography>
        <br />
        <Typography className={textClass}>
          La collecte et le traitement des données répondent aux finalités
          suivantes :
        </Typography>
        <br />
        <Typography className={textClass}>
          Communication promotionnelle
        </Typography>
        <br />
        <Typography className={textClass}>
          Les traitements de données effectués sont fondés sur les bases légales
          suivantes :
        </Typography>
        <br />
        <Typography className={textClass}>
          - L'exécution du contrat et de la promesse du site
        </Typography>
        <br />
        <Typography className={textClass}>
          - Le consentement de l'Utilisateur
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>B.</strong> TRANSMISSION DES DONNÉES A DES TIERS
        </Typography>
        <br />
        <Typography className={textClass}>
          Les données à caractère personnel collectées par le site ne sont
          transmises à aucun tiers, et ne sont traitées que par l'éditeur du
          site.
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>C.</strong> HÉBERGEMENT DES DONNÉES
        </Typography>
        <br />
        <Typography className={textClass}>
          Le site www.formation.paul-wery.fr est hébergé par :{" "}
        </Typography>
        <br />
        <Typography className={textClass}>
          Firebase Inc (22 4th Street Suite 1000 San Francisco, CA 94103 United
          States, www.firebase.com)
        </Typography>
        <br />
        <Typography className={textClass}>
          Les données collectées et traitées par le site sont transférées vers
          le(s) pays suivant(s) : France, États-Unis. Ce transfert de données à
          caractère personnel en dehors de l'Union européenne se justifie par
          les raisons ci-après :
        </Typography>
        <br />
        <Typography className={textClass}>
          Certains outils ont leurs serveurs aux États-Unis.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES
        </Typography>
        <br />

        <Typography className={textClass}>
          <strong>A.</strong> LE RESPONSABLE DU TRAITEMENT DES DONNÉES
        </Typography>
        <br />
        <Typography className={textClass}>
          Le responsable du traitement des données à caractère personnel est :
          Paul Wéry. Il peut être contacté de la manière suivante :
        </Typography>
        <br />
        <Typography className={textClass}>
          paulweryformation@gmail.com
        </Typography>
        <br />
        <Typography className={textClass}>
          Le responsable du traitement des données est chargé de déterminer les
          finalités et les moyens mis au service du traitement des données à
          caractère personnel.
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>B.</strong> OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES
          DONNÉES
        </Typography>
        <br />
        <Typography className={textClass}>
          Le responsable du traitement s'engage à protéger les données à
          caractère personnel collectées, à ne pas les transmettre à des tiers
          sans que l'utilisateur n'en ait été informé et à respecter les
          finalités pour lesquelles ces données ont été collectées.
        </Typography>
        <br />
        <Typography className={textClass}>
          Le site dispose d'un certificat SSL afin de garantir que les
          informations et le transfert des données transitant par le site sont
          sécurisés.
        </Typography>
        <br />
        <Typography className={textClass}>
          Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de
          sécuriser les données échangées entre l'utilisateur et le site.
        </Typography>
        <br />
        <Typography className={textClass}>
          De plus, le responsable du traitement des données s'engage à notifier
          l'utilisateur en cas de rectification ou de suppression des données, à
          moins que cela n'entraîne pour lui des formalités, coûts et démarches
          disproportionnés.
        </Typography>
        <br />
        <Typography className={textClass}>
          Dans le cas où l'intégrité, la confidentialité ou la sécurité des
          données à caractère personnel de l'utilisateur est compromise, le
          responsable du traitement s'engage à informer l'utilisateur par tout
          moyen.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 5 : DROITS DE L'UTILISATEUR
        </Typography>
        <br />

        <Typography className={textClass}>
          Conformément à la réglementation concernant le traitement des données
          à caractère personnel, l'utilisateur possède les droits ci-après
          énumérés.
        </Typography>
        <br />
        <Typography className={textClass}>
          Afin que le responsable du traitement des données fasse droit à sa
          demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom
          ainsi que son adresse e-mail, et si cela est pertinent, son numéro de
          compte ou d'espace personnel ou d'abonné.
        </Typography>
        <br />
        <Typography className={textClass}>
          Le responsable du traitement des données est tenu de répondre à
          l'utilisateur dans un délai de 30 (trente) jours maximum.
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>A.</strong> PRÉSENTATION DES DROITS DE L'UTILISATEUR EN
          MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>a. Droit d'accès, de rectification et droit à l'effacement</i>
        </Typography>
        <br />
        <Typography className={textClass}>
          L'utilisateur peut prendre connaissance, mettre à jour, modifier ou
          demander la suppression des données le concernant, en respectant la
          procédure ci-après énoncée :
        </Typography>
        <br />
        <Typography className={textClass}>
          L'Utilisateur d'envoyer un e-mail au responsable de traitement des
          données en expliquant le motif de sa demande. Pour cela il peut
          utiliser l'adresse de contact.
        </Typography>
        <br />
        <Typography className={textClass}>
          S'il en possède un, l'utilisateur a le droit de demander la
          suppression de son espace personnel en suivant la procédure suivante :
        </Typography>
        <br />
        <Typography className={textClass}>
          L'Utilisateur d'envoyer un e-mail au responsable de traitement des
          données en expliquant le motif de sa demande. Pour cela il peut
          utiliser l'adresse de contact.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>b. Droit à la portabilité des données</i>
        </Typography>
        <br />
        <Typography className={textClass}>
          L'utilisateur a le droit de demander la portabilité de ses données
          personnelles, détenues par le site, vers un autre site, en se
          conformant à la procédure ci-après :
        </Typography>
        <br />
        <Typography className={textClass}>
          L'Utilisateur d'envoyer un e-mail au responsable de traitement des
          données en expliquant le motif de sa demande. Pour cela il peut
          utiliser l'adresse de contact.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>
            c. Droit à la limitation et à l'opposition du traitement des données
          </i>
        </Typography>
        <br />
        <Typography className={textClass}>
          L'utilisateur a le droit de demander la limitation ou de s'opposer au
          traitement de ses données par le site, sans que le site ne puisse
          refuser, sauf à démontrer l'existence de motifs légitimes et
          impérieux, pouvant prévaloir sur les intérêts et les droits et
          libertés de l'utilisateur.
        </Typography>
        <br />
        <Typography className={textClass}>
          Afin de demander la limitation du traitement de ses données ou de
          formuler une opposition au traitement de ses données, l'utilisateur
          doit suivre la procédure suivante :
        </Typography>
        <br />
        <Typography className={textClass}>
          L'Utilisateur d'envoyer un e-mail au responsable de traitement des
          données en expliquant le motif de sa demande. Pour cela il peut
          utiliser l'adresse de contact.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>
            d. Droit de ne pas faire l'objet d'une décision fondée exclusivement
            sur un procédé automatisé
          </i>
        </Typography>
        <br />
        <Typography className={textClass}>
          Conformément aux dispositions du règlement 2016/679, l'utilisateur a
          le droit de ne pas faire l'objet d'une décision fondée exclusivement
          sur un procédé automatisé si la décision produit des effets juridiques
          le concernant, ou l'affecte de manière significative de façon
          similaire.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>e. Droit de déterminer le sort des données après la mort</i>
        </Typography>
        <br />
        <Typography className={textClass}>
          Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le
          devenir de ses données collectées et traitées s'il décède,
          conformément à la loi n°2016-1321 du 7 octobre 2016.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>f. Droit de saisir l'autorité de contrôle compétente</i>
        </Typography>
        <br />
        <Typography className={textClass}>
          Dans le cas où le responsable du traitement des données décide de ne
          pas répondre à la demande de l'utilisateur, et que l'utilisateur
          souhaite contester cette décision, ou, s'il pense qu'il est porté
          atteinte à l'un des droits énumérés ci-dessus, il est en droit de
          saisir la CNIL (Commission Nationale de l'Informatique et des
          Libertés, https://www.cnil.fr) ou tout juge compétent.
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>B.</strong> DONNÉES PERSONNELLES DES PERSONNES MINEURES
        </Typography>
        <br />
        <Typography className={textClass}>
          Conformément aux dispositions de l'article 8 du règlement européen
          2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés
          de 15 ans ou plus peuvent consentir au traitement de leurs données
          personnelles.
        </Typography>
        <br />
        <Typography className={textClass}>
          Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un
          représentant légal sera requis afin que des données à caractère
          personnel puissent être collectées et traitées.
        </Typography>
        <br />
        <Typography className={textClass}>
          L'éditeur du site se réserve le droit de vérifier par tout moyen que
          l'utilisateur est âgé de plus de 15 ans, ou qu'il aura obtenu l'accord
          d'un représentant légal avant de naviguer sur le site.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 6 : UTILISATION DES FICHIERS "COOKIES"
        </Typography>
        <br />

        <Typography className={textClass}>
          Le site a éventuellement recours aux techniques de "cookies".{" "}
        </Typography>
        <br />
        <Typography className={textClass}>
          Un "cookie" est un fichier de petite taille (moins de 4 ko), stocké
          par le site sur le disque dur de l'utilisateur, contenant des
          informations relatives aux habitudes de navigation de l'utilisateur.
        </Typography>
        <br />
        <Typography className={textClass}>
          Ces fichiers lui permettent de traiter des statistiques et des
          informations sur le trafic, de faciliter la navigation et d'améliorer
          le service pour le confort de l'utilisateur.
        </Typography>
        <br />
        <Typography className={textClass}>
          Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et
          l'analyse de données à caractère personnel, le consentement de
          l'utilisateur est nécessairement demandé.
        </Typography>
        <br />
        <Typography className={textClass}>
          Ce consentement de l'utilisateur est considéré comme valide pour une
          durée de 6 (six) mois maximum. A l'issue de cette période, le site
          demandera à nouveau l'autorisation de l'utilisateur pour enregistrer
          des fichiers "cookies" sur son disque dur.
        </Typography>
        <br />
        <Typography className={textClass}>
          <i>
            a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies"
            par le site
          </i>
        </Typography>
        <br />
        <Typography className={textClass}>
          Les cookies non essentiels au fonctionnement du site ne sont déposés
          sur le terminal de l'utilisateur qu'après avoir obtenu son
          consentement. L'utilisateur peut retirer son consentement à tout
          moment, de la manière suivante :
        </Typography>
        <br />
        <Typography className={textClass}>
          L'Utilisateur d'envoyer un e-mail au responsable de traitement des
          données en expliquant le motif de sa demande. Pour cela il peut
          utiliser l'adresse de contact.
        </Typography>
        <br />
        <Typography className={textClass}>
          De manière plus générale, il est porté à la connaissance de
          l'utilisateur qu'il peut s'opposer à l'enregistrement de ces fichiers
          "cookies" en configurant son logiciel de navigation.
        </Typography>
        <br />
        <Typography className={textClass}>
          Pour information, l'utilisateur peut trouver aux adresses suivantes
          les démarches à suivre afin de configurer son logiciel de navigation
          pour s'opposer à l'enregistrement des fichiers "cookies" :
        </Typography>
        <br />
        <ul className={listClass}>
          <Typography className={textClass}>
            <li>
              <strong>Chrome</strong> :
              https://support.google.com/accounts/answer/61416?hl=fr
            </li>
            <li>
              <strong>Firefox</strong> :
              https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences
            </li>
            <li>
              <strong>Safari</strong> :
              http://www.apple.com/legal/privacy/fr-ww/
            </li>
            <li>
              <strong>Internet Explorer</strong> :
              https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
            </li>
            <li>
              <strong>Opera</strong> :
              http://www.opera.com/help/tutorials/security/cookies/
            </li>
          </Typography>
        </ul>
        <br />
        <Typography className={textClass}>
          Dans le cas où l'utilisateur décide de désactiver les fichiers
          "cookies", il pourra poursuivre sa navigation sur le site. Toutefois,
          tout dysfonctionnement du site provoqué par cette manipulation ne
          pourrait être considéré comme étant du fait de l'éditeur du site.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 7 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
          CONFIDENTIALITÉ
        </Typography>
        <br />

        <Typography className={textClass}>
          La présente politique de confidentialité peut être consultée à tout
          moment à l'adresse ci-après indiquée :
        </Typography>
        <br />
        <Typography className={textClass}>
          https://formation.paul-wery.fr/vie-privee/
        </Typography>
        <br />
        <Typography className={textClass}>
          L'éditeur du site se réserve le droit de la modifier afin de garantir
          sa conformité avec le droit en vigueur.
        </Typography>
        <br />
        <Typography className={textClass}>
          Par conséquent, l'utilisateur est invité à venir consulter
          régulièrement cette politique de confidentialité afin de se tenir
          informé des derniers changements qui lui seront apportés.
        </Typography>
        <br />
        <Typography className={textClass}>
          Toutefois, en cas de modification substantielle de cette politique,
          l'utilisateur en sera informé de la manière suivante :
        </Typography>
        <br />
        <Typography className={textClass}>
          Par email à l'adresse indiquée par l'utilisateur
        </Typography>
        <br />
        <Typography className={textClass}>
          Il est porté à la connaissance de l'utilisateur que la dernière mise à
          jour de la présente politique de confidentialité est intervenue le :
          22/04/2022.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 8 : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE
          CONFIDENTIALITÉ
        </Typography>
        <br />

        <Typography className={textClass}>
          En naviguant sur le site, l'utilisateur atteste avoir lu et compris la
          présente politique de confidentialité et en accepte les conditions, en
          ce qui concerne plus particulièrement la collecte et le traitement de
          ses données à caractère personnel, ainsi que l'utilisation de fichiers
          "cookies".
        </Typography>
      </Container>
    </Box>
  );
};
