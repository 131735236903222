import { css } from "@emotion/css";
import { Box, Container, Typography } from "@mui/material";
import React from "react";

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  font-size: 2.5em !important;
  font-weight: bold !important;
  padding-top: 50px;
`;

const subTitleClass = css`
  color: #222222;
  font-size: 1.8em !important;
  font-weight: bold !important;
`;

const pageTitleCardClass = css`
  background-color: #1a237e;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const pageTitleClass = css`
  text-align: center;
  color: white;
  font-size: 3em !important;
  font-weight: bold !important;
`;

const pageSubTitleClass = css`
  text-align: center;
  color: white;
  font-size: 2em !important;
  font-weight: bold !important;
`;

export const WarrantyView: React.FC = () => {
  return (
    <Box>
      <Box className={pageTitleCardClass}>
        <Container>
          <Typography className={pageTitleClass}>
            Conditions de remboursement
          </Typography>
          <br />
          <Typography className={pageSubTitleClass}>Garantie</Typography>
        </Container>
      </Box>
      <Container
        maxWidth="sm"
        className={css`
          border-bottom: 1px solid black;
          padding-bottom: 100px;
          margin-bottom: 200px;
        `}
      >
        <Typography className={titleClass}>
          Pour toutes les formations
        </Typography>
        <br />
        <Typography className={subTitleClass}>
          - Garantie satisfait ou remboursé de 30 jours sans conditions.{" "}
        </Typography>
        <br />
        <Typography className={textClass}>
          Le client peut demander un remboursement complet après achat s’il
          envoie un email au support à l’adresse paulweryformation@gmail.com
          dans les 30 jours après la validation de sa commande sur l’outil de
          paiement Stripe.
        </Typography>
        <br />
        <Typography className={textClass}>
          Aucune question ne sera posée.
        </Typography>
        <br />
        <Typography className={textClass}>
          <strong>À noter</strong> : toute demande de déblocage complet du
          produit avant la fin de la cette garantie annulera son effet après
          confirmation tacite de la part du client.
        </Typography>
      </Container>
    </Box>
  );
};
