import React from "react";
import { css } from "@emotion/css";
import { Box, Button, Container, Typography } from "@mui/material";

const titleClass = css`
  font-weight: bold !important;
  font-size: 2.2em !important;
  @media (max-width: 420px) {
    font-size: 2em !important;
  }
`;

export const ContactSection: React.FC = () => {
  return (
    <section
      className={css`
        text-align: center;
        color: black;
        background-color: white;
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container>
        <Typography className={titleClass}>
          Intéressé mais hésitant ?
        </Typography>
        <br />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            className={css`
              font-weight: bold !important;
              font-size: 1.5em !important;
            `}
          >
            <a
              href="mailto:paulweryformation@gmail.com?subject=Demande d'information"
              className={css`
                color: inherit;
                text-decoration: none;
              `}
            >
              Contactez-moi
            </a>
          </Button>
        </Box>
      </Container>
    </section>
  );
};
