import React, { useEffect } from "react";
import { sendEvent } from "../../api/sendEvent";
import { EventName } from "../../constants";
import { useDetectScroll } from "../../hooks/detectScroll";
import { CatchSection } from "./sections/catchSection";
import { ContactSection } from "./sections/contactSection";
import { FormationOpeningSection } from "./sections/formationOpeningSection";
import { FormationSection } from "./sections/formationSection";
import { PricingSection } from "./sections/pricingSection";
import { ProfileSection } from "./sections/profileSection";
import { StorySection } from "./sections/storySection";
import { WarrantySection } from "./sections/warrantySection";

export const DiscoveryFormationView: React.FC = () => {
  useEffect(() => {
    sendEvent(EventName.PageView);
  }, []);
  useDetectScroll();

  return (
    <div>
      <CatchSection />
      <StorySection />
      <ProfileSection />
      <FormationOpeningSection />
      <FormationSection />
      <PricingSection />
      <WarrantySection />
      <ContactSection />
    </div>
  );
};
