import React from "react";
import { css } from "@emotion/css";
import { Container, Typography, Box } from "@mui/material";

const textClass = css`
  color: white;
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  color: white;
  font-size: 2.2em !important;
  text-align: center;
`;

export const ProfileSection: React.FC = () => {
  return (
    <section
      className={css`
        background-color: rgba(59, 89, 152, 1);
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container maxWidth="md">
        <Typography className={titleClass} id="section-2-0">
          Qui suis-je ?
        </Typography>
        <br />
        <Box display="flex" justifyContent="center">
          <img
            src="/profile.jpg"
            alt=""
            width={150}
            height={150}
            className={css`
              border-radius: 100%;
            `}
          />
        </Box>
        <Typography className={textClass} id="section-2-1">
          <br />
          Bonjour je m'appelle Paul.
          <br />
          <br />
          Je me suis lancé dans la programmation il y a maintenant 8 ans. Je ne
          comprenais rien à l'algorithmie et ne connaissais pas grand chose au
          métier. Et pourtant j'ai voulu tenter, me donner une chance de
          découvrir si ça allait me plaire. Et je ne pense pas avoir fait le
          meilleur choix à ce moment là. J'ai pris un crédit pour faire une
          école avec le risque d'abandonner après quelques jours. Mais j'ai été
          chanceux. Ça m'a énormément plu et j'ai fini par devenir bon et à
          m'améliorer jusqu'à aujourd'hui.
          <br />
          <br />
          Je suis maintenant diplômé d'un master à l'école d'EPITECH et
          freelance depuis 3 ans. Ce fut par chance que j'en suis arrivé là. Si
          je m'étais lancé dans le code et que ça ne m'avait pas plu j'aurais
          perdu énormément.
          <br />
          <br />
          C'est pour cette raison que j'ai lancé cette formation. Pour éviter
          que des personnes jouent à pile ou face comme je l'ai fait sans savoir
          dans quoi ils s'embarquent.
          <br />
        </Typography>
      </Container>
    </section>
  );
};
