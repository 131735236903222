import { useEffect } from "react";
import $ from "jquery";
import { sendReadingState } from "../api/sendEvent";

const ranges: Record<string, [number, number]> = {
  "#section-1": [0, 2],
  "#section-2": [0, 1],
  "#section-3": [0, 1],
  "#section-4": [0, 9],
  "#section-5": [0, 1],
  "#section-6": [0, 1],
};

const buildState = () => {
  const state: Record<string, boolean> = {};
  const entries = Object.entries(ranges);

  for (const [section, range] of entries) {
    for (let id = range[0]; id <= range[1]; id++) {
      state[`${section}-${id}`] = false;
    }
  }
  return state;
};

const sectionsState = buildState();

export const useDetectScroll = () => {
  const onScroll = () => {
    const scrollPosition = $(window).scrollTop();
    const entries = Object.entries(ranges);

    for (const [section, range] of entries) {
      for (let id = range[0]; id <= range[1]; id++) {
        const sectionId = `${section}-${id}`;
        const anchorOffset = $(sectionId).offset()?.top;
        const isValid = anchorOffset && scrollPosition;

        if (
          isValid &&
          !sectionsState[sectionId] &&
          scrollPosition > anchorOffset
        ) {
          sectionsState[sectionId] = true;
          sendReadingState(sectionsState);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    sendReadingState(sectionsState);
  }, []);
};
