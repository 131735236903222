import { css } from "@emotion/css";
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  const scrollToTop = () => window.scroll(0, 0);

  return (
    <Box
      className={css`
        color: white;
        text-align: center;
        background-color: #000022;
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      ©2022 |{" "}
      <Link
        to="/cgv"
        className={css`
          color: inherit;
        `}
        onClick={scrollToTop}
      >
        CGV
      </Link>{" "}
      et{" "}
      <Link
        to="/vie-privee"
        className={css`
          color: inherit;
        `}
        onClick={scrollToTop}
      >
        confidentialité
      </Link>{" "}
      -{" "}
      <Link
        to="/garantie"
        className={css`
          color: inherit;
        `}
        onClick={scrollToTop}
      >
        Politique de remboursement
      </Link>
    </Box>
  );
};
