import React from "react";
import { css } from "@emotion/css";
import { Container, Typography } from "@mui/material";

const formationNameClass = css`
  font-size: 3.5em !important;
  @media (max-width: 420px) {
    font-size: 3em !important;
  }
  color: white;
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase !important;
`;

const formationSubtitleClass = css`
  color: white;
  font-size: 2.2em !important;
  @media (max-width: 420px) {
    font-size: 2em !important;
  }
`;

export const CatchSection: React.FC = () => {
  return (
    <section
      className={css`
        text-align: center;
        background-color: rgba(59, 89, 152, 1);
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container>
        <Typography className={formationNameClass}>La découverte</Typography>
        <br />
        <Typography className={formationSubtitleClass}>
          "C’est impossible. Ce n’est pas pour moi. Jamais je n’y arriverai"
        </Typography>
        <br />
        <Typography
          className={css`
            color: white;
            font-size: 1.6em !important;
            font-style: italic;
          `}
        >
          Êtes-vous vraiment sûr que la programmation n'est pas faite pour vous
          ?
        </Typography>
      </Container>
    </section>
  );
};
