import { css } from "@emotion/css";
import { Container, Typography } from "@mui/material";
import React from "react";

const formationNameClass = css`
  font-size: 3.5em !important;
  @media (max-width: 420px) {
    font-size: 3em !important;
  }
  text-align: center;
  font-weight: bold !important;
  text-transform: uppercase !important;
`;

const textClass = css`
  font-size: 1.5em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  font-size: 2.5em !important;
  text-align: center;
  font-style: italic;
`;

export const FormationOpeningSection: React.FC = () => {
  return (
    <section
      className={css`
        background-color: white;
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container maxWidth="md">
        <Typography className={formationNameClass}>La découverte</Typography>
        <br />
        <Typography className={titleClass} id="section-3-0">
          Découvrez si le métier de développeur <br />
          est fait pour vous
        </Typography>
        <br />
        <br />
        <Typography className={textClass} id="section-3-1">
          Dans ce programme, vous allez être accompagné dans l'apprentissage des
          <strong> bases du code</strong> mais également découvrir ce qu'est{" "}
          <strong>le travail d'un développeur</strong>.
          <br />
          <br />À la fin, vous saurez si ce métier est fait pour
          vous.
        </Typography>
      </Container>
    </section>
  );
};
