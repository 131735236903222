import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { IS_DEVELOPMENT } from "./constants";
import "./index.css";

(() => {
  if (IS_DEVELOPMENT) {
    Cookies.set("_fbp", "fb.1.1558571054389.1098115397");
    Cookies.set(
      "_fbc",
      "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890"
    );
  }
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
