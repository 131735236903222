import { css } from "@emotion/css";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const titleClass = css`
  font-size: 2em !important;
  font-weight: bold !important;
  padding-top: 50px;
`;

const pageTitleCardClass = css`
  background-color: #1a237e;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const pageTitleClass = css`
  text-align: center;
  color: white;
  font-size: 3em !important;
  font-weight: bold !important;
`;

export const CGVView: React.FC = () => {
  return (
    <Box>
      <Box className={pageTitleCardClass}>
        <Container>
          <Typography className={pageTitleClass}>
            CONDITIONS GÉNÉRALES DE VENTE
            <br />
            ET D’UTILISATION
          </Typography>
        </Container>
      </Box>
      <Container
        maxWidth="sm"
        className={css`
          border-bottom: 1px solid black;
          padding-bottom: 100px;
          margin-bottom: 200px;
        `}
      >
        <Typography className={titleClass}>VUE D’ENSEMBLE</Typography>
        <br />

        <Typography className={textClass}>
          Ce site web est exploité par PAUL WERY. Sur ce site, les termes
          "nous", "notre" et "nos" font référence à PAUL WERY. PAUL WERY propose
          ce site web, y compris toutes les informations, tous les outils et
          tous les services qui y sont disponibles pour vous, l’utilisateur,
          sous réserve de votre acceptation de l’ensemble des modalités,
          conditions, politiques et avis énoncés ici.
        </Typography>
        <br />
        <Typography className={textClass}>
          En visitant ce site et/ou en achetant un de nos produits, vous vous
          engagez dans notre "Service" et acceptez d’être lié par les modalités
          suivantes ("Conditions Générales de Vente", "Conditions Générales de
          Vente et d’Utilisation", "Conditions"), y compris les modalités,
          conditions et politiques additionnelles auxquelles il est fait
          référence ici et/ou accessibles par hyperlien. Ces Conditions
          Générales de Vente et d’Utilisation s’appliquent à tous les
          utilisateurs de ce site, incluant mais ne se limitant pas, aux
          utilisateurs qui naviguent sur le site, qui sont des vendeurs, des
          clients, des marchands, et/ou des contributeurs de contenu.
        </Typography>
        <br />
        <Typography className={textClass}>
          Veuillez lire attentivement ces Conditions Générales de Vente et
          d’Utilisation avant d’accéder à ou d’utiliser notre site web. En
          accédant à ou en utilisant une quelconque partie de ce site, vous
          acceptez d’être lié par ces Conditions Générales de Vente et
          d’Utilisation. Si vous n’acceptez pas toutes les modalités et toutes
          les Conditions de cet accord, alors vous ne devez pas accéder au site
          web ni utiliser les services qui y sont proposés. Si ces Conditions
          Générales de Vente et d’Utilisation sont considérées comme une offre,
          l’acceptation se limite expressément à ces Conditions Générales de
          Vente et d’Utilisation.
        </Typography>
        <br />
        <Typography className={textClass}>
          Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui
          seront ajoutés ultérieurement à cette boutique seront également
          assujettis à ces Conditions Générales de Vente et d’Utilisation. Vous
          pouvez consulter la version la plus récente des Conditions Générales
          de Vente et d’Utilisation à tout moment sur cette page. Nous nous
          réservons le droit de mettre à jour, de changer ou de remplacer toute
          partie de ces Conditions Générales de Vente et d’Utilisation en
          publiant les mises à jour et/ou les modifications sur notre site web.
          Il vous incombe de consulter cette page régulièrement pour vérifier si
          des modifications ont été apportées. Votre utilisation continue du
          site web ou votre accès à celui-ci après la publication de toute
          modification constitue une acceptation de votre part de ces
          modifications.
        </Typography>
        <br />
        <Typography className={textClass}>
          Notre site est hébergé sur Firebase. Ils nous fournissent la
          plate-forme e-commerce qui nous permet de vous vendre nos produits et
          services.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 1 – CONDITIONS D’UTILISATION DE NOTRE BOUTIQUE EN LIGNE
        </Typography>
        <br />

        <Typography className={textClass}>
          En acceptant ces Conditions Générales de Vente et d’Utilisation, vous
          déclarez que vous avez atteint l’âge de la majorité dans votre pays,
          État ou province de résidence, et que vous nous avez donné votre
          consentement pour permettre à toute personne d’âge mineur à votre
          charge d’utiliser ce site web.
        </Typography>
        <br />
        <Typography className={textClass}>
          L’utilisation de nos produits à toute fin illégale ou non autorisée
          est interdite, et vous ne devez pas non plus, dans le cadre de
          l’utilisation du Service, violer les lois de votre juridiction
          (incluant mais ne se limitant pas aux lois relatives aux droits
          d’auteur).
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous ne devez pas transmettre de vers, de virus ou tout autre code de
          nature destructive.
        </Typography>
        <br />
        <Typography className={textClass}>
          Toute infraction ou violation des présentes Conditions Générales de
          Vente et d’Utilisation entraînera la résiliation immédiate de vos
          Services.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 2 – CONDITIONS GÉNÉRALES
        </Typography>
        <br />

        <Typography className={textClass}>
          Nous nous réservons le droit de refuser à tout moment l’accès aux
          services à toute personne, et ce, pour quelque raison que ce soit.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous comprenez que votre contenu (à l’exclusion de vos informations de
          carte de crédit) pourrait être transféré de manière non chiffrée, et
          cela sous-entend (a) des transmissions sur divers réseaux; et (b) des
          changements pour se conformer et s’adapter aux exigences techniques
          pour la connexion des réseaux ou appareils. Les informations de carte
          de crédit sont toujours chiffrées pendant la transmission sur les
          réseaux.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous acceptez de ne pas reproduire, dupliquer, copier, vendre,
          revendre ou exploiter une quelconque partie du Service ou utilisation
          du Service, ou un quelconque accès au Service ou contact sur le site
          web, par le biais duquel le Service est fourni, sans autorisation
          écrite expresse préalable de notre part.
        </Typography>
        <br />
        <Typography className={textClass}>
          Les titres utilisés dans cet accord sont inclus pour votre commodité,
          et ne vont ni limiter ni affecter ces Conditions.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 3 – EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS
        </Typography>
        <br />

        <Typography className={textClass}>
          Nous ne sommes pas responsables si les informations disponibles sur ce
          site ne sont pas exactes, complètes ou à jour. Le contenu de ce site
          est fourni à titre indicatif uniquement et ne devrait pas constituer
          votre seule source d’information pour prendre des décisions, sans
          consulter au préalable des sources d’information plus exactes, plus
          complètes et actualisées. Si vous décidez de vous fier au contenu
          présenté sur ce site, vous le faites à votre propre risque.
        </Typography>
        <br />
        <Typography className={textClass}>
          Ce site pourrait contenir certaines informations antérieures. Ces
          informations antérieures, par nature, ne sont pas à jour et sont
          fournies à titre indicatif seulement. Nous nous réservons le droit de
          modifier le contenu de ce site à tout moment, mais nous n’avons aucune
          obligation de mettre à jour les informations sur notre site. Vous
          acceptez qu’il vous incombe de surveiller les modifications apportées
          à notre site.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 4 – MODIFICATIONS APPORTÉES AU SERVICE ET AUX PRIX
        </Typography>
        <br />

        <Typography className={textClass}>
          Les prix de nos produits peuvent être modifiés sans préavis.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous nous réservons le droit à tout moment de modifier ou
          d’interrompre le Service (ainsi que toute partie ou tout contenu du
          Service) sans préavis et en tout temps.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous ne serons pas responsables envers vous ou toute autre tierce
          partie de toute modification de prix, suspension ou interruption du
          Service.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 5 – PRODUITS OU SERVICES
        </Typography>
        <br />

        <Typography className={textClass}>
          Certains produits ou services peuvent être exclusivement disponibles
          en ligne sur notre site web. Ces produits ou services peuvent être
          disponibles en quantités limitées et peuvent uniquement faire l’objet
          de remboursements ou d’échanges conformément à notre{" "}
          <Link
            to="/garantie"
            className={css`
              color: inherit;
            `}
          >
            Politique de Garantie
          </Link>
          .
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous avons fait de notre mieux pour afficher aussi clairement que
          possible les couleurs et images de nos produits qui apparaissent sur
          notre boutique. Nous ne pouvons pas garantir que l’affichage des
          couleurs par l’écran de votre ordinateur sera précis.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous nous réservons le droit, sans toutefois être obligés de le faire,
          de limiter les ventes de nos produits ou services à toute personne, et
          dans toute région géographique ou juridiction. Nous pourrions exercer
          ce droit au cas par cas. Nous nous réservons le droit de limiter les
          quantités de tout produit ou service que nous offrons. Toutes les
          descriptions de produits et tous les prix des produits peuvent être
          modifiés en tout temps sans avis préalable, à notre seule discrétion.
          Nous nous réservons le droit d’arrêter d’offrir un produit à tout
          moment. Toute offre de service ou de produit présentée sur ce site est
          nulle là où la loi l’interdit.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous ne garantissons pas que la qualité de tous les produits,
          services, informations, ou toute autre marchandise que vous avez
          obtenue ou achetée répondra à vos attentes, ni que toute erreur dans
          le Service sera corrigée.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 6 – EXACTITUDE DE LA FACTURATION ET DES INFORMATIONS DE COMPTE
        </Typography>
        <br />

        <Typography className={textClass}>
          Nous nous réservons le droit de refuser toute commande que vous passez
          auprès de nous. Ces restrictions pourraient inclure des commandes
          passées par ou depuis le même compte client, la même carte de crédit,
          et/ou des commandes qui utilisent la même adresse de facturation. Dans
          le cas où nous modifierions une commande ou si nous venions à
          l’annuler, nous pourrions tenter de vous avertir en vous contactant à
          l’e-mail et/ou à l’adresse de facturation/au numéro de téléphone
          fourni au moment où la commande a été passée. Nous nous réservons le
          droit de limiter ou d’interdire les commandes qui, à notre seul
          jugement, pourraient sembler provenir de marchands, de revendeurs ou
          de distributeurs.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous acceptez de fournir des informations de commande et de compte à
          jour, complètes et exactes pour toutes les commandes passées sur notre
          boutique. Vous vous engagez à mettre à jour rapidement votre compte et
          vos autres informations, y compris votre adresse e-mail, vos numéros
          de cartes de crédit et dates d’expiration, pour que nous poussions
          compléter vos transactions et vous contacter si nécessaire.
        </Typography>
        <br />
        <Typography className={textClass}>
          Pour plus de détails, veuillez consulter notre{" "}
          <Link
            to="/garantie"
            className={css`
              color: inherit;
            `}
          >
            Politique de Garantie
          </Link>
          .
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 7 – OUTILS FACULTATIFS
        </Typography>
        <br />

        <Typography className={textClass}>
          Nous pourrions vous fournir l’accès à des outils de tierces parties
          sur lesquels nous n’exerçons ni suivi, ni contrôle, ni influence.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous reconnaissez et acceptez le fait que nous fournissons l’accès à
          de tels outils "tels quels" et "selon la disponibilité", sans aucune
          garantie, représentation ou condition d’aucune sorte et sans aucune
          approbation. Nous n’aurons aucune responsabilité légale résultant de
          ou liée à l’utilisation de ces outils facultatifs de tiers.
        </Typography>
        <br />
        <Typography className={textClass}>
          Si vous utilisez les outils facultatifs offerts sur le site, vous le
          faites à votre propre risque et à votre propre discrétion, et vous
          devriez consulter les conditions auxquelles ces outils sont offerts
          par le ou les fournisseurs tiers concerné(s).
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous pourrions aussi, à l’avenir, offrir de nouveaux services et/ou de
          nouvelles fonctionnalités sur notre site (incluant de nouveaux outils
          et de nouvelles ressources). Ces nouvelles fonctionnalités et ces
          nouveaux services seront également assujettis à ces Conditions
          Générales de Vente et d’Utilisation.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 8 – LIENS DE TIERS
        </Typography>
        <br />

        <Typography className={textClass}>
          Certains contenus, produits et services disponibles par le biais de
          notre Service pourraient inclure des éléments provenant de tierces
          parties.
        </Typography>
        <br />
        <Typography className={textClass}>
          Les liens provenant de tierces parties sur ce site pourraient vous
          rediriger vers des sites web de tiers qui ne sont pas affiliés à nous.
          Nous ne sommes pas tenus d’examiner ou d’évaluer le contenu ou
          l’exactitude de ces sites, et nous ne garantissons pas et n’assumons
          aucune responsabilité quant à tout contenu, site web, produit, service
          ou autre élément accessible sur ou depuis ces sites tiers.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous ne sommes pas responsables des préjudices ou dommages liés à
          l’achat ou à l’utilisation de biens, de services, de ressources, de
          contenu, ou de toute autre transaction effectuée en rapport avec ces
          sites web de tiers. Veuillez lire attentivement les politiques et
          pratiques des tierces parties et assurez-vous de bien les comprendre
          avant de vous engager dans toute transaction. Les plaintes,
          réclamations, préoccupations, ou questions concernant les produits de
          ces tiers doivent être soumises à ces mêmes tiers.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 9 – COMMENTAIRES, SUGGESTIONS ET AUTRES PROPOSITIONS
          D’UTILISATEURS
        </Typography>
        <br />

        <Typography className={textClass}>
          Si, à notre demande, vous soumettez des contenus spécifiques (par
          exemple, pour participer à des concours), ou si sans demande de notre
          part, vous envoyez des idées créatives, des suggestions, des
          propositions, des plans ou d’autres éléments, que ce soit en ligne,
          par e-mail, par courrier, ou autrement (collectivement,
          "commentaires"), vous nous accordez le droit, en tout temps, et sans
          restriction, d’éditer, de copier, de publier, de distribuer, de
          traduire et d’utiliser autrement et dans tout média tout commentaire
          que vous nous envoyez. Nous ne sommes pas et ne devrons pas être tenus
          (1) de maintenir la confidentialité des commentaires; (2) de payer une
          compensation à quiconque pour tout commentaire fourni; (3) de répondre
          aux commentaires.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous pourrions, mais n’avons aucune obligation de le faire,
          surveiller, modifier ou supprimer le contenu que nous estimons, à
          notre seule discrétion, être illégal, offensant, menaçant, injurieux,
          diffamatoire, pornographique, obscène ou autrement répréhensible, ou
          qui enfreint toute propriété intellectuelle ou ces Conditions
          Générales de Vente et d’Utilisation.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous vous engagez à écrire des commentaires qui ne violent pas les
          droits de tierces parties, y compris les droits d’auteur, les marques
          déposées, la confidentialité, la personnalité, ou d’autres droits
          personnels ou de propriété. Vous convenez également que vos
          commentaires ne contiendront pas de contenu illégal, diffamatoire,
          offensif ou obscène, et qu’ils ne contiendront non plus pas de virus
          informatique ou d’autres logiciels malveillants qui pourraient
          affecter de quelque manière que ce soit le fonctionnement du Service
          ou tout autre site web associé. Vous ne pouvez pas utiliser de fausse
          adresse e-mail, prétendre être quelqu’un que vous n’êtes pas, ou
          essayer de nous induire nous et/ou les tierces parties en erreur quant
          à l’origine de vos commentaires. Vous êtes entièrement responsable de
          tous les commentaires que vous publiez ainsi que de leur exactitude.
          Nous n’assumons aucune responsabilité et déclinons tout engagement
          quant à tout commentaire que vous publiez ou que toute autre tierce
          partie publie.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 10 – RENSEIGNEMENTS PERSONNELS
        </Typography>
        <br />

        <Typography className={textClass}>
          La soumission de vos renseignements personnels sur notre boutique est
          régie par notre Politique de Confidentialité.{" "}
          <Link
            to="/vie-privee"
            className={css`
              color: inherit;
            `}
          >
            Cliquez ici pour consulter notre Politique de Confidentialité
          </Link>
          .
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 11 – ERREURS, INEXACTITUDES ET OMISSIONS
        </Typography>
        <br />

        <Typography className={textClass}>
          Il se pourrait qu’il y ait parfois des informations sur notre site ou
          dans le Service qui pourraient contenir des erreurs typographiques,
          des inexactitudes ou des omissions qui pourraient être relatives aux
          descriptions de produits, aux prix, aux promotions, aux offres, aux
          frais d’expédition des produits, aux délais de livraison et à la
          disponibilité. Nous nous réservons le droit de corriger toute erreur,
          inexactitude, omission, et de changer ou de mettre à jour des
          informations ou d’annuler des commandes, si une quelconque information
          dans le Service ou sur tout autre site web associé est inexacte, et
          ce, en tout temps et sans préavis (y compris après que vous ayez passé
          votre commande).
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous ne sommes pas tenus de mettre à jour, de modifier ou de clarifier
          les informations dans le Service ou sur tout autre site web associé,
          incluant mais ne se limitant pas aux informations sur les prix, sauf
          si requis par la loi. Aucune date définie de mise à jour ou
          d’actualisation dans le Service ou sur tout autre site web associé ne
          devrait être prise en compte pour conclure que les informations dans
          le Service ou sur tout autre site web associé ont été modifiées ou
          mises à jour.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 12 – UTILISATIONS INTERDITES
        </Typography>
        <br />

        <Typography className={textClass}>
          En plus des interdictions énoncées dans les Conditions Générales de
          Vente et d’Utilisation, il vous est interdit d’utiliser le site ou son
          contenu: (a) à des fins illégales; (b) pour inciter des tiers à
          réaliser des actes illégaux ou à y prendre part; (c) pour enfreindre
          toute ordonnance régionale ou toute loi, règle ou régulation
          internationale, fédérale, provinciale ou étatique; (d) pour porter
          atteinte à ou violer nos droits de propriété intellectuelle ou ceux de
          tierces parties; (e) pour harceler, maltraiter, insulter, blesser,
          diffamer, calomnier, dénigrer, intimider ou discriminer quiconque en
          fonction du sexe, de l’orientation sexuelle, de la religion, de
          l’origine ethnique, de la race, de l’âge, de l’origine nationale, ou
          d’un handicap; (f) pour soumettre des renseignements faux ou
          trompeurs; (g) pour téléverser ou transmettre des virus ou tout autre
          type de code malveillant qui sera ou pourrait être utilisé de manière
          à compromettre la fonctionnalité ou le fonctionnement du Service ou de
          tout autre site web associé, indépendant, ou d’Internet; (h) pour
          recueillir ou suivre les renseignements personnels d’autrui; (i) pour
          polluposter, hameçonner, détourner un domaine, extorquer des
          informations, parcourir, explorer ou balayer le web (ou toute autre
          ressource); (j) à des fins obscènes ou immorales; ou (k) pour porter
          atteinte ou contourner les mesures de sécurité de notre Service, de
          tout autre site web, ou d’Internet. Nous nous réservons le droit de
          résilier votre utilisation du Service ou de tout site web connexe pour
          avoir enfreint les utilisations interdites.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 13 – EXCLUSION DE GARANTIES ET LIMITATION DE RESPONSABILITÉ
        </Typography>
        <br />

        <Typography className={textClass}>
          Nous ne garantissons ni ne prétendons en aucun cas que votre
          utilisation de notre Service sera ininterrompue, rapide, sécurisée ou
          sans erreur.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous ne garantissons pas que les résultats qui pourraient être obtenus
          par le biais de l’utilisation du Service seront exacts ou fiables.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous acceptez que de temps à autre, nous puissions supprimer le
          Service pour des périodes de temps indéfinies ou annuler le Service à
          tout moment, sans vous avertir au préalable.
        </Typography>
        <br />
        <Typography className={textClass}>
          Vous convenez expressément que votre utilisation du Service, ou votre
          incapacité à utiliser celui-ci, est à votre seul risque. Le Service
          ainsi que tous les produits et services qui vous sont fournis par le
          biais du Service sont (sauf mention expresse du contraire de notre
          part) fournis "tels quels" et "selon la disponibilité" pour votre
          utilisation, et ce sans représentation, sans garanties et sans
          conditions d'aucune sorte, expresses ou implicites, y compris toutes
          les garanties implicites de commercialisation ou de qualité marchande,
          d’adaptation à un usage particulier, de durabilité, de titre et
          d’absence de contrefaçon.
        </Typography>
        <br />
        <Typography className={textClass}>
          PAUL WERY, nos directeurs, responsables, employés, sociétés affiliées,
          agents, contractants, stagiaires, fournisseurs, prestataires de
          services et concédants ne peuvent en aucun cas être tenus responsables
          de toute blessure, perte, réclamation, ou de dommages directs,
          indirects, accessoires, punitifs, spéciaux, ou dommages consécutifs de
          quelque nature qu’ils soient, incluant mais ne se limitant pas à la
          perte de profits, de revenus, d’économies, de données, aux coûts de
          remplacement ou tous dommages similaires, qu’ils soient contractuels,
          délictuels (même en cas de négligence), de responsabilité stricte ou
          autre, résultant de votre utilisation de tout service ou produit
          provenant de ce Service, ou quant à toute autre réclamation liée de
          quelque manière que ce soit à votre utilisation du Service ou de tout
          produit, incluant mais ne se limitant à toute erreur ou omission dans
          tout contenu, ou à toute perte ou tout dommage de toute sorte
          découlant de l’utilisation du Service ou de tout contenu (ou produit)
          publié, transmis, ou autrement rendu disponible par le biais du
          Service, même si vous avez été avertis de la possibilité qu’ils
          surviennent. Parce que certains États ou certaines juridictions ne
          permettent pas d’exclure ou de limiter la responsabilité quant aux
          dommages consécutifs ou accessoires, notre responsabilité sera limitée
          dans la mesure maximale permise par la loi.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 14 – INDEMNISATION
        </Typography>
        <br />

        <Typography className={textClass}>
          Vous acceptez d’indemniser, de défendre et de protéger PAUL WERY,
          notre société-mère, nos filiales, sociétés affiliées, partenaires,
          responsables, directeurs, agents, contractants, concédants,
          prestataires de services, sous-traitants, fournisseurs, stagiaires et
          employés, quant à toute réclamation ou demande, incluant les
          honoraires raisonnables d’avocat, faite par toute tierce partie à
          cause de ou découlant de votre violation de ces Conditions Générales
          de Vente et d’Utilisation ou des documents auxquels ils font
          référence, ou de votre violation de toute loi ou des droits d’un
          tiers.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 15 – DISSOCIABILITÉ
        </Typography>
        <br />

        <Typography className={textClass}>
          Dans le cas où une disposition des présentes Conditions Générales de
          Vente et d’Utilisation serait jugée comme étant illégale, nulle ou
          inapplicable, cette disposition pourra néanmoins être appliquée dans
          la pleine mesure permise par la loi, et la partie non applicable devra
          être considérée comme étant dissociée de ces Conditions Générales de
          Vente et d’Utilisation, cette dissociation ne devra pas affecter la
          validité et l’applicabilité de toutes les autres dispositions
          restantes.
        </Typography>
        <br />

        <Typography className={titleClass}>ARTICLE 16 – RÉSILIATION</Typography>
        <br />

        <Typography className={textClass}>
          Les obligations et responsabilités engagées par les parties avant la
          date de résiliation resteront en vigueur après la résiliation de cet
          accord et ce à toutes les fins.
        </Typography>
        <br />
        <Typography className={textClass}>
          Ces Conditions Générales de Vente et d’Utilisation sont effectives à
          moins et jusqu’à ce qu’elles soient résiliées par ou bien vous ou non.
          Vous pouvez résilier ces Conditions Générales de Vente et
          d’Utilisation à tout moment en nous avisant que vous ne souhaitez plus
          utiliser nos Services, ou lorsque vous cessez d’utiliser notre site.
        </Typography>
        <br />
        <Typography className={textClass}>
          Si nous jugeons, à notre seule discrétion, que vous échouez, ou si
          nous soupçonnons que vous avez été incapable de vous conformer aux
          modalités de ces Conditions Générales de Vente et d’Utilisation, nous
          pourrions aussi résilier cet accord à tout moment sans vous prévenir à
          l’avance et vous resterez responsable de toutes les sommes redevables
          jusqu’à la date de résiliation (celle-ci étant incluse), et/ou nous
          pourrions vous refuser l’accès à nos Services (ou à toute partie de
          ceux-ci).
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 17 – INTÉGRALITÉ DE L’ACCORD
        </Typography>
        <br />

        <Typography className={textClass}>
          Tout manquement de notre part à l’exercice ou à l’application de tout
          droit ou de toute disposition des présentes Conditions Générales de
          Vente et d’Utilisation ne devrait pas constituer une renonciation à ce
          droit ou à cette disposition.
        </Typography>
        <br />
        <Typography className={textClass}>
          Ces Conditions Générales de Vente et d’Utilisation ou toute autre
          politique ou règle d’exploitation que nous publions sur ce site ou
          relativement au Service constituent l’intégralité de l’entente et de
          l’accord entre vous et nous et régissent votre utilisation du Service,
          et remplacent toutes les communications, propositions et tous les
          accords, antérieurs et contemporains, oraux ou écrits, entre vous et
          nous (incluant, mais ne se limitant pas à toute version antérieure des
          Conditions Générales de Vente et d’Utilisation).
        </Typography>
        <br />
        <Typography className={textClass}>
          Toute ambiguïté quant à l’interprétation de ces Conditions Générales
          de Vente et d’Utilisation ne doit pas être interprétée en défaveur de
          la partie rédactrice.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 18 – LOI APPLICABLE
        </Typography>
        <br />

        <Typography className={textClass}>
          Ces Conditions Générales de Vente et d’Utilisation, ainsi que tout
          autre accord séparé par le biais duquel nous vous fournissons des
          Services seront régis et interprétés en vertu des lois en vigueur en
          France.
        </Typography>
        <br />

        <Typography className={titleClass}>
          ARTICLE 19 – MODIFICATIONS APPORTÉES AUX CONDITIONS GÉNÉRALES DE VENTE
          ET D’UTILISATION
        </Typography>
        <br />

        <Typography className={textClass}>
          Vous pouvez consulter la version la plus récente des Conditions
          Générales de Vente et d’Utilisation à tout moment sur cette page.
        </Typography>
        <br />
        <Typography className={textClass}>
          Nous nous réservons le droit, à notre seule discrétion, de mettre à
          jour, de modifier ou de remplacer toute partie de ces Conditions
          Générales de Vente et d’Utilisation en publiant les mises à jour et
          les changements sur notre site. Il vous incombe de visiter notre site
          régulièrement pour vérifier si des changements ont été apportés. Votre
          utilisation continue de ou votre accès à notre site après la
          publication de toute modification apportée à ces Conditions Générales
          de Vente et d’Utilisation constitue une acceptation de ces
          modifications.
        </Typography>
        <br />

        <Typography className={titleClass}>ARTICLE 20 – COORDONNÉES</Typography>
        <br />

        <Typography className={textClass}>
          Les questions concernant les Conditions Générales de Vente et
          d’Utilisation devraient nous être envoyées à
          paulweryformation@gmail.com.
        </Typography>
      </Container>
    </Box>
  );
};
