import { css } from "@emotion/css";
import { Container, Typography } from "@mui/material";
import React from "react";

const bigTitleClass = css`
  color: white;
  font-size: 3.5em !important;
  @media (max-width: 420px) {
    font-size: 3em !important;
  }
  text-align: center;
  font-weight: bold !important;
`;

const titleClass = css`
  color: white;
  font-size: 2em !important;
  font-weight: bold !important;
`;

const textClass = css`
  color: white;
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;

export const WarrantySection: React.FC = () => {
  return (
    <section
      className={css`
        background-color: rgba(59, 89, 152, 1);
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <Container maxWidth="md">
        <Typography className={bigTitleClass} id="section-6-0">
          Ma Double Garantie
        </Typography>
        <br />
        <Typography className={titleClass} id="section-6-1">
          Garantie accompagnement
        </Typography>
        <br />
        <Typography className={textClass} id="section-6-2">
          En rejoignant La Découverte - Premium aujourd’hui, vous accédez à l’espace
          communauté de la formation. Vous pourrez alors me poser toutes vos
          questions.
          <br />
          <br />
          En parallèle, vous pouvez interagir directement avec moi et tous les
          membres de la formation sur le forum dédié, directement dans votre
          espace membre.
          <br />
          <br />
          Vous ne serez jamais seul dans votre apprentissage.
        </Typography>
        <br />
        <Typography className={titleClass}>
          Garantie Satisfait ou Remboursé 30 jours
        </Typography>
        <br />
        <Typography className={textClass}>
          Testez pendant 30 jours PUIS décidez si vous voulez garder la
          formation.
          <br />
          <br />
          Après votre commande, vous avez 30 jours pour consulter la formation,
          la suivre - et décider si elle a répondu à vos attentes.
          <br />
          <br />
          Si vous n’êtes pas satisfait, il vous suffit d’envoyer un email pour
          être remboursé à 100% - quelle que soit votre raison. Aucune question
          ne vous sera posée.
        </Typography>
        <br />
      </Container>
    </section>
  );
};
