import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./components/footer";
import { CGVView } from "./views/cgv/cgvView";
import { DiscoveryFormationView } from "./views/discoveryFormationView/discoveryFormationView";
import { PrivacyPolicyView } from "./views/privacyPolicy/privacyPolicyView";
import { WarrantyView } from "./views/warranty/warrantyView";

export const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DiscoveryFormationView />} />
          <Route path="/cgv" element={<CGVView />} />
          <Route path="/vie-privee" element={<PrivacyPolicyView />} />
          <Route path="/garantie" element={<WarrantyView />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};
