import { css } from "@emotion/css";
import { Check } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { sendEvent } from "../../../api/sendEvent";
import { EventName } from "../../../constants";

const textClass = css`
  font-size: 1.2em !important;
  line-height: 1.8em !important;
`;
const bigTitleClass = css`
  font-size: 3.5em !important;
  @media (max-width: 420px) {
    font-size: 3em !important;
  }
  text-align: center;
  font-weight: bold !important;
`;
const moduleTitle = css`
  font-size: 1.7em !important;
  font-weight: bold !important;
`;
const cardClass = css`
  color: white !important;
  background-color: rgba(59, 89, 152, 1);
  max-width: 400px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-grow: 1;
`;
const buyButtonClass = css`
  margin-top: 10px !important;
  font-weight: bold !important;
  border-radius: 0 !important;
  text-transform: initial !important;
  color: black !important;
  background-color: white !important;
  font-size: 1.8em !important;
  :hover {
    background-color: #dddddd !important;
  }
`;

export const PricingSection: React.FC = () => {
  const onFreeAccess = () => {
    sendEvent(EventName.Lead);
    window.location.href =
      "https://paul-wery.schoolmaker.co/purchases/new?price_id=c9762449-e821-48b0-a1da-ee5e6c4df9e4";
  };
  const onPurchase = () => {
    sendEvent(EventName.Lead);
    window.location.href =
      "https://paul-wery.schoolmaker.co/purchases/new?price_id=37eb1321-7b5c-4686-ad0d-fc83613b6ab6";
  };

  return (
    <section
      className={css`
        background-color: white;
        padding-top: 50px;
        padding-bottom: 50px;
        ul {
          padding: 0;
          list-style: none;
          li {
            display: flex;
            margin-bottom: 10px;
          }
          svg {
            margin-top: 5px;
            margin-right: 10px;
          }
        }
      `}
    >
      <Container maxWidth="lg">
        <Typography className={bigTitleClass} id="section-5-0">
          Accédez à <i>La Découverte</i> :
        </Typography>
        <br />
        <Box display="flex" justifyContent="center">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className={cardClass}>
                <Typography className={moduleTitle} id="section-5-1">
                  La Découverte (Gratuite) :
                </Typography>
                <ul>
                  <Typography className={textClass}>
                    <li>
                      <Check /> Accès de 7 jours à la formation et à l'espace
                      communauté
                    </li>
                    <li>
                      <Check /> 5 modules de formation
                    </li>
                    <li>
                      <Check /> 3h de Format vidéo + slides téléchargeables
                    </li>
                    <br />
                    <strong>Tarif de la version : Gratuite</strong>
                  </Typography>
                </ul>
                <Button
                  variant="contained"
                  fullWidth
                  className={buyButtonClass}
                  onClick={onFreeAccess}
                >
                  S'enregistrer
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={cardClass}>
                <Typography className={moduleTitle} id="section-5-1">
                  La Découverte - Premium :
                </Typography>
                <ul>
                  <Typography className={textClass}>
                    <li>
                      <Check /> Accès à vie à la formation et à l'espace
                      communauté
                    </li>
                    <li>
                      <Check /> 5 modules de formation
                    </li>
                    <li>
                      <Check /> 3h de Format vidéo + slides téléchargeables
                    </li>
                    <li>
                      <Check />
                      <span>
                        <strong>1h d'accompagnement</strong> pour me poser
                        toutes vos questions
                      </span>
                    </li>
                    <li>
                      <Check />
                      <span>
                        Ma <strong>Double Garantie</strong>
                      </span>
                    </li>
                    <br />
                    <strong>Tarif de la version : 47 €</strong>
                  </Typography>
                </ul>
                <Button
                  variant="contained"
                  fullWidth
                  className={buyButtonClass}
                  onClick={onPurchase}
                >
                  Commander la formation
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};
