import axios from "axios";
import Cookies from "js-cookie";
import {
  EventName,
  FACEBOOK_ADS_ROUTE,
  IS_DEVELOPMENT,
  READING_STATE_ROUTE,
  SERVER_URL,
} from "../constants";

export const sendEvent = async (eventName: EventName) => {
  const eventTime = Math.floor(Date.now() / 1000);

  for (let index = 0; index < 10; index++) {
    if (Cookies.get("_fbp")) {
      break;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  try {
    await axios.post(`${SERVER_URL}/${FACEBOOK_ADS_ROUTE}`, {
      eventName,
      eventTime,
      fbp: Cookies.get("_fbp"),
      fbc: Cookies.get("_fbc"),
      isTest: IS_DEVELOPMENT,
    });
  } catch (error) {
    console.error(error);
  }
};

export const sendReadingState = async (state: Record<string, boolean>) => {
  try {
    await axios.post(`${SERVER_URL}/${READING_STATE_ROUTE}`, {
      state,
      fbp: Cookies.get("_fbp"),
      fbc: Cookies.get("_fbc"),
      isTest: IS_DEVELOPMENT,
    });
  } catch (error) {
    console.error(error);
  }
};
